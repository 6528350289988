import styled, {css} from "styled-components";
import React from "react";

const Title = styled.h1`
 color: black;
 size: 2rem;
 position: absolute;
 left: 2rem;
 top: 2rem;
 border: solid 7px rgba(0,0,0, 0.6);
 padding: 5px;
 font-style: italic;
 
   ${props => props.light && css`

       color: white;
       border-color: rgba(255,255,255, 0.6)
  `}
`;

const Logo = ({light}) => {
    return <Title light={light} onClick={() => {
        window.location = "/";
    }}>κXTalk</Title>;
};

export default Logo;
