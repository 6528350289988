import styled, {css} from "styled-components";

export const Layout = styled.div`
  background-color: black;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 10px;
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
`;

export const ErrorWrapper = styled.div`
  font-family: PixelMillennium, Helvetica;
  color: white;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
`;

export const NewLineContainer = styled.div`
 
  display: flex;
  flex-direction: column;
  
`;

export const DownBtn = styled.button`

    font-family:  'Courier New',Courier,monospace;
    font-weight: bold;
    background-color: deeppink;
   border: none;
    border-bottom: solid blue 4px;
    border-right: solid blue 4px;
    border-radius: 0;
    font-size:1.5rem;
    margin-right: 1rem;
    cursor: grab;
    
    :focus {
      outline: none;
    }
    
    :active {
       background-color: orchid;
       border-bottom: solid mediumblue 4px;
       border-right: solid mediumblue 4px;
    }
      ${props => props.isActive && css`


        background-color: green;

  `}

      ${props => props.isActive && props.isPassiv && css`
        background-color: yellow;
        pointer-events: none;
  `}


`;


export const GroupInputs = styled.div`
    display:flex;
    margin-bottom: 10px;
    
      ${props => props.align === "space" && css`
        justify-content: space-between;
  `}

`;
export const InputWrapper = styled.input`
    font-family:  'Courier New',Courier,monospace;
    background-color: white;
    border: none;
    border-radius: 0;
    font-size:1.5rem;
    padding-left: 0.5rem;
    border-bottom: solid lightslategrey 4px;
    border-right: solid lightslategrey 4px;
     margin-right: 1rem;
    :focus {
      outline: none;
    }

`;
export const Links = styled.a`
  

`;
