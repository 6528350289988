import React, {Suspense} from 'react';
import { Canvas, useLoader, useFrame} from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import * as THREE from 'three';
import Logo from "../components/common/Logo";

function Asset({ url, onClick }) {
    React.useEffect(() => {
        gltf.materials.Material.color = new THREE.Color('pink')
    },[]);
    const gltf = useLoader(GLTFLoader, url);
    const ref = React.createRef();
    return <primitive onPointerDown={onClick} object={gltf.scene}  dispose={null} ref={ref}>

    </primitive>
}
/*
<pointLight position={[2, 5, -1.2]} color={0xFFF} distance={4.82} intensity={3.25}/>

<pointLight position={[2.1, 0.83, 0]} color={0xFFF} distance={4} intensity={2.6}/>

<pointLight position={[-1.67, 0.35, 0]} color={0xFFF} distance={5} intensity={2.2}/>

<pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF} distance={4.4} intensity={2.6}/>
 */


const TheScene = ({next, }) => {
    return  <scene>

        <directionalLight position={[0,-2.5,0]} color={"white"}/>
        <group position={[0,-2.5,0]} scale={[0.4,0.4,0.4]}>
            <Suspense fallback={null}>
                <Asset onClick={next} url="/model/medusasmaller.glb" />
            </Suspense>
        </group>

    </scene>
}

const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function App() {
    return (
        <div className="App">
            <Canvas style={{ height: "100vh", width: "100vw", background: "white"}}>
                <scene>
                    <TheScene onClick={() => {}}/>
                </scene>
            </Canvas>
            <Logo/>
        </div>
    );
}

export default App;
