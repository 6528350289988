import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import Landing from './containers/Landing';
import PostDetail from './containers/Post/detail';
import PostCreate from './containers/Post/create';
function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Switch>
                <Route path="/posts/:name"  component={PostDetail} />
                <Route path="/posts"  component={PostDetail} />
                <Route path="/private/posts/create"  component={PostCreate} />
                <Route path="/"  component={Landing} />
            </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
