
import Logo from "../../components/common/Logo";
import Chat from "../../components/chat";
import React, {useState} from "react";
import { If } from "react-if";
import axios from "axios";
import {Layout, Content, ErrorWrapper} from "../../components/common/Layout";

function App({match:{params:{name}}}) {
    const [chat, setChat] = useState(false);
    const [error, setError] = useState(false);

    React.useEffect(() => {
        axios.get('/res/posts/' + name + '.json', {
        })
            .then(function (response) {
                setChat(response.data);
            })
            .catch(function (error) {
                setError(() => true);
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    },[]);

    return (
        <Layout>
            <Content>
                <div style={{marginTop: "120px"}}>
                    <If condition={!!chat}>
                        <Chat onClick={() =>{}} data={chat}/>
                    </If>
                    <If condition={!chat && !error}>
                        <ErrorWrapper>
                            Loading
                        </ErrorWrapper>
                    </If>
                    <If condition={error}>
                        <ErrorWrapper>
                            Something went wrong
                        </ErrorWrapper>
                    </If>
                </div>
            </Content>

            <Logo light/>

        </Layout>
    );
}

export default App;
