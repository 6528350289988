
import Logo from "../../components/common/Logo";
import Chat from "../../components/chat";
import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import { If } from "react-if";
import axios from "axios";
import {Layout, Content, ErrorWrapper, DownBtn, GroupInputs, InputWrapper, NewLineContainer, Links} from "../../components/common/Layout";

function uniqueNumber() {
    var date = Date.now();

    // If created at same millisecond as previous
    if (date <= uniqueNumber.previous) {
        date = ++uniqueNumber.previous;
    } else {
        uniqueNumber.previous = date;
    }

    return date;
}

uniqueNumber.previous = 0;

function generateChatEntry (message, who) {
    return {
        "who":who,
        "id": uniqueNumber().toString(),
        "text": message,
    };
};

function generateGhostEntry () {
    return {
        "who":"me",
        "id": uniqueNumber().toString(),
        "type":"ghost",
        "text":""
    };
};

const NewLineWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  
`;

function getEntryId(chat, id) {
    return chat.findIndex(x => x.id === id);
};

const LoadFinish = ({onLoad}) => {
    const [file, setFile] = useState("");
    return <GroupInputs>
            <InputWrapper value={file} placeholder={"Enter published code"} onChange={event => setFile(event.target.value)} />
            <DownBtn onClick={() => {
            axios.get('/res/posts/' + file + '.json', {
            })
                .then(function (response) {
                    onLoad(() => response.data);
                })
                .catch(function (error) {
                   // setError(() => true);
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });

            }}>Load</DownBtn>
        </GroupInputs>
};


const LoadSaveDraft = ({chat, onLoad}) => {
    const [file, setFile] = useState("chat");
    return <GroupInputs>
        <InputWrapper value={file} onChange={event => setFile(event.target.value)} />
        <DownBtn onClick={() => {
            if(chat.length !== 1) {
                const data = JSON.stringify(chat);
                window.localStorage.setItem(file, data);
            }
        }}>Save</DownBtn>
        <DownBtn onClick={() => {
            const data = JSON.parse(window.localStorage.getItem(file));
            onLoad(() => data);
        }}>Load</DownBtn>
    </GroupInputs>
};

const DownloadCurrent = ({chat}) => {
    const [file, setFile] = useState("chat");
    return <GroupInputs>
        <InputWrapper value={file} onChange={event => setFile(event.target.value)} />
        <DownBtn><Links href={download(chat)} download={file + ".json"}>download</Links></DownBtn>
    </GroupInputs>
};



const NewLine = ({chat, onAddClick, edit, onLoad}) => {
    const [message, setMessage] = useState("");
    const [who, setWho] = useState("me");
    const [name, setName] = useState("friend");
    const [isOpenMore, setIsOpenMore] = useState(false);

    useEffect(() => {
        if(edit) {
            const index = getEntryId(chat, edit);
            setWho(() => chat[index].who);
            if(chat[index].who !== "me") {
                setName(() => chat[index].who);
            }
            setMessage(() => chat[index].text);
        }
    }, [edit]);
    return <NewLineWrapper>
        <NewLineContainer>
            <GroupInputs>
                <InputWrapper value={name} onChange={event => setName(event.target.value)} />
                <DownBtn isActive={isOpenMore} onClick={
                    () => setIsOpenMore(i => !i)
                }>More</DownBtn>
            </GroupInputs>
            <GroupInputs>
                <InputWrapper value={message} onChange={event => setMessage(event.target.value)} />
            <DownBtn onClick={
                () => {
                    onAddClick(message, who);
                    setMessage(() => "");
                }}>{edit ? "Update" : "Add"}</DownBtn>

            </GroupInputs>
            <GroupInputs>
                <DownBtn isActive={who === "me"} isPassiv={who === "me"} onClick={
                    () => setWho("me")
                }>Me</DownBtn>
                <DownBtn isActive={who !== "me"} isPassiv={who !== "me"} onClick={
                    () => setWho(name)
                }>Other</DownBtn>

            </GroupInputs>
            <If condition={isOpenMore}>
             <DownloadCurrent chat={chat}/>
            </If>

            <If condition={isOpenMore}>
                <LoadFinish onLoad={onLoad} />
            </If>

            <If condition={isOpenMore}>
                <LoadSaveDraft chat={chat} onLoad={onLoad} />
            </If>

        </NewLineContainer>
    </NewLineWrapper>
};

function download(data) {
        var exportData = 'data:text/json;charset=utf-8,';
        exportData += encodeURIComponent(JSON.stringify(data));
      //  const encodedUri = encodeURI(exportData);
        //const newWindow = window.open(encodedUri);
        return exportData;
}


function App({match:{params:{name}}}) {
    const [chat, setChat] = useState([generateGhostEntry()]);
    const [edit, setEdit] = useState(false);

    const [error, setError] = useState(false);

    const onActionClick = (message, who) => {
        if(!edit) {
            setChat((data) => [...data,generateChatEntry(message,who)])
        } else {
            const newValue = chat.map(i => {
                if(i.id === edit) {
                    return generateChatEntry(message, who);
                }
                return i
            });

            setChat(() => newValue);
            setEdit(false);
        }

    };

    return (
        <Layout>
            <Content>
                <div style={{marginTop: "120px"}}>
                    <If condition={!!chat}>
                        <Chat status={edit} onClick={(id) => setEdit(id)} data={chat}/>
                    </If>

                    <If condition={chat.length === 1}>
                        <ErrorWrapper>
                            Start
                        </ErrorWrapper>
                    </If>

                    <If condition={error}>
                        <ErrorWrapper>
                            Something went wrong
                        </ErrorWrapper>
                    </If>
                </div>
            </Content>

            <Logo light/>
            <NewLine chat={chat} edit={edit} onLoad={setChat} onAddClick={onActionClick}/>

        </Layout>
    );
}

export default App;
