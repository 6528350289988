import React from "react";
import styled, {css} from "styled-components";

const BubbleContainer = styled.div`
   display: flex;
   max-width: 300px;
   min-width: 300px;
   background-color: blueviolet;
   color: white;
   margin-bottom: 2px;
   border: deeppink double 3px;
   ${props => props.who !== "me" && css`

    background-color: green;
    color: white;
  `}
   
    ${props => props.emoji && css`
     background: transparent;
    border: none;
    font-size: 2rem;
   
    `}
    
    ${props => props.emoji && props.who === "me" && css`

     justify-content: flex-end;
   
    `}
  
   
`;
const BubbleWrapper = styled.div`
   display: flex;
    ${props => props.who !== "me" && css` 
      margin-left: -50px;
  `}
      ${props => props.space && css`
       margin-bottom: 10px
  `}
         ${props => props.visable && css`

       display: none;
  `}
     
    
`;


var emoji_regex = /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])$/;


const ChatBubble = ({who,id, space, onClick, text, ...props}) => {
    const emoji = emoji_regex.test(text);

    return <BubbleWrapper  onPointerDown={onClick} space={space} who={who}>
        <BubbleContainer emoji={emoji} who={who}>
            <div style={{margin: "10px"}}>
        {props.children}
      </div>
        </BubbleContainer>
    </BubbleWrapper>
};

const Chat = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Courier New',Courier,monospace;
  font-weight: bold;
 
`;


const ChatContainer = (props) => {
    return <Chat>{props.children}</Chat>
};

const ChatMaschineText = ({data, who, space, onClick}) => {
    if(data.type === "ghost") {
        return <div onClick={onClick} style={{margin: 20}}></div>
    }
    return <ChatBubble onClick={onClick} text={data.text} id={data.id} space={space} who={who} >
        {data.text}
    </ChatBubble>
};

const isSpace = (item, data, index) => {
    if(index + 1 >= data.length) return true;
    if(item.who === data[index + 1].who) {
        return false;
    }
    return true;
};

const ChatMaschine = ({data, state, up, onClick, ...props}) => {
   return <>
        {data.map((i, index) =>
            <ChatMaschineText onClick={() => onClick(i.id)} key={"chatmaschine-group-" + i.id} up={up} space={isSpace(i, data, index)} number={index} data={i} who={i.who} state={state} />
        )}
    </>
};


function App(props) {
    return (
            <ChatContainer>
                <ChatMaschine onClick={props.onClick} data={props.data}/>
                <div style={{margin: "100px"}}></div>
            </ChatContainer>
    );
}

export default App;
